/**********************************************************************
*
*   Component generated by Quest
*
*   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts. 
*   To preseve that abilty, always export from Quest to regenerate this file.
*   To setup props, bindings and actions, use the Quest editor
*   Code Logic goes in the hook associated with this component
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/

import { TextField, Button, SvgIcon } from '@mui/material';
import XImage from './assets/images/x.png';
import { ReactComponent as ArrowForwardFilled1 } from './assets/images/ArrowForwardFilled.svg';
import { ReactComponent as CheckCircleFilled1 } from './assets/images/CheckCircleFilled.svg';
import { styled } from '@mui/material/styles';
import usePaymentCard from './usePaymentCard';
 
const TypeQuest = styled("div")({  
  backgroundColor: "rgba(255, 255, 255, 1)",  
  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.25)",  
  borderRadius: "10px",  
  display: "flex",  
  flexDirection: "row",  
  width: "728px",  
  height: "574px",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "40px",  
  padding: "40px",  
  boxSizing: "border-box",  
  overflow: "hidden",  
});
  
const Image = styled("div", {
    shouldForwardProp: prop => !["bgImage1"].includes(prop)
  })(({ bgImage1 }) =>({  
  backgroundImage: `url(${bgImage1})`,  
  backgroundPosition: "center",  
  backgroundSize: "cover",  
  backgroundRepeat: "no-repeat",  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "40px",  
  padding: "0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
  width: "304px",  
}));
  
const Form = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "space-between",  
  alignItems: "flex-end",  
  padding: "0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
  width: "304px",  
});
  
const Frame1 = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "24px",  
  padding: "0px",  
  boxSizing: "border-box",  
});
  
const TextTop = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "2px",  
  padding: "0px",  
  boxSizing: "border-box",  
  width: "304px",  
});
  
const TopRow = styled("div")({  
  display: "flex",  
  flexDirection: "row",  
  justifyContent: "space-between",  
  alignItems: "flex-start",  
  padding: "0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
});
  
const Text = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "2px",  
  padding: "0px",  
  boxSizing: "border-box",  
});
  
const ConfirmPurchase = styled("div")(({ theme }) =>({  
  textAlign: "left",  
  color: "rgba(0, 0, 0, 1)",  
  fontStyle: theme.typography["Typography"]["H5"].fontStyle,  
  fontFamily: theme.typography["Typography"]["H5"].fontFamily,  
  fontWeight: theme.typography["Typography"]["H5"].fontWeight,  
  fontSize: theme.typography["Typography"]["H5"].fontSize,  
  letterSpacing: theme.typography["Typography"]["H5"].letterSpacing,  
  textDecoration: theme.typography["Typography"]["H5"].textDecoration,  
  lineHeight: theme.typography["Typography"]["H5"].lineHeight,  
  textTransform: theme.typography["Typography"]["H5"].textTransform,  
}));
  
const ItemName = styled("div")({  
  textAlign: "left",  
  color: "rgba(0, 0, 0, 1)",  
  fontStyle: "normal",  
  fontFamily: "Heebo",  
  fontWeight: "400",  
  fontSize: "20px",  
  letterSpacing: "0px",  
  textDecoration: "none",  
  textTransform: "none",  
});
  
const ItemPrice = styled("div")({  
  textAlign: "left",  
  color: "rgba(0, 0, 0, 1)",  
  fontStyle: "normal",  
  fontFamily: "Heebo",  
  fontWeight: "700",  
  fontSize: "18px",  
  letterSpacing: "0px",  
  textDecoration: "none",  
  textTransform: "none",  
});
  
const X = styled("img")({  
  height: "12px",  
  width: "12px",  
  cursor: "pointer",  
});
  
const Frame3 = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "40px",  
  padding: "0px",  
  boxSizing: "border-box",  
  width: "304px",  
});
  
const Row1 = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "30px",  
  padding: "0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
});
  
const TextFieldStandard = styled(TextField)({  
  alignSelf: "stretch",  
});
  
const TextFieldStandard1 = styled(TextField)({  
  alignSelf: "stretch",  
});
  
const Row2 = styled("div")({  
  display: "flex",  
  flexDirection: "row",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "30px",  
  padding: "0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
});
  
const TextFieldStandard2 = styled(TextField)({  
  width: "58px",  
});
  
const TextFieldStandard3 = styled(TextField)({  
  flexGrow: "1",  
});
  
const Q123 = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-end",  
  gap: "24px",  
  padding: "0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
});
  
const AutoAddedFrame = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-end",  
  gap: "10px",  
  padding: "0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
});
  
const Success = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "center",  
  alignItems: "flex-start",  
  gap: "60px",  
  padding: "0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
  width: "304px",  
});
  
const Text1 = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "14px",  
  padding: "0px",  
  boxSizing: "border-box",  
});
  
const Frame2 = styled("div")({  
  borderRadius: "40px",  
  display: "flex",  
  flexDirection: "row",  
  justifyContent: "center",  
  alignItems: "center",  
  gap: "10px",  
  padding: "0px",  
  boxSizing: "border-box",  
  height: "30px",  
  width: "30px",  
  overflow: "hidden",  
});
  
const Icon1 = styled(SvgIcon)(({ theme }) =>({  
  color: theme.palette["Light"]["Action"]["Active (54p)"],  
}));
  
const Frame11 = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "4px",  
  padding: "0px",  
  boxSizing: "border-box",  
});
  
const PaymentConfirmed = styled("div")(({ theme }) =>({  
  textAlign: "left",  
  color: "rgba(0, 0, 0, 1)",  
  fontStyle: theme.typography["Typography"]["H5"].fontStyle,  
  fontFamily: theme.typography["Typography"]["H5"].fontFamily,  
  fontWeight: theme.typography["Typography"]["H5"].fontWeight,  
  fontSize: theme.typography["Typography"]["H5"].fontSize,  
  letterSpacing: theme.typography["Typography"]["H5"].letterSpacing,  
  textDecoration: theme.typography["Typography"]["H5"].textDecoration,  
  lineHeight: theme.typography["Typography"]["H5"].lineHeight,  
  textTransform: theme.typography["Typography"]["H5"].textTransform,  
}));
  
const AConfirmationEmailHa = styled("div")(({ theme }) =>({  
  textAlign: "left",  
  color: "rgba(0, 0, 0, 1)",  
  fontStyle: theme.typography["Typography"]["H6"].fontStyle,  
  fontFamily: theme.typography["Typography"]["H6"].fontFamily,  
  fontWeight: theme.typography["Typography"]["H6"].fontWeight,  
  fontSize: theme.typography["Typography"]["H6"].fontSize,  
  letterSpacing: theme.typography["Typography"]["H6"].letterSpacing,  
  textDecoration: theme.typography["Typography"]["H6"].textDecoration,  
  lineHeight: theme.typography["Typography"]["H6"].lineHeight,  
  textTransform: theme.typography["Typography"]["H6"].textTransform,  
}));
  
const Text2 = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "4px",  
  padding: "0px",  
  boxSizing: "border-box",  
});
  
const OrderReference = styled("div")(({ theme }) =>({  
  textAlign: "left",  
  color: "rgba(0, 0, 0, 1)",  
  fontStyle: theme.typography["Typography"]["H5"].fontStyle,  
  fontFamily: theme.typography["Typography"]["H5"].fontFamily,  
  fontWeight: theme.typography["Typography"]["H5"].fontWeight,  
  fontSize: theme.typography["Typography"]["H5"].fontSize,  
  letterSpacing: theme.typography["Typography"]["H5"].letterSpacing,  
  textDecoration: theme.typography["Typography"]["H5"].textDecoration,  
  lineHeight: theme.typography["Typography"]["H5"].lineHeight,  
  textTransform: theme.typography["Typography"]["H5"].textTransform,  
}));
  
const Q0021488192 = styled("div")(({ theme }) =>({  
  textAlign: "left",  
  color: "rgba(0, 0, 0, 1)",  
  fontStyle: theme.typography["Typography"]["H6"].fontStyle,  
  fontFamily: theme.typography["Typography"]["H6"].fontFamily,  
  fontWeight: theme.typography["Typography"]["H6"].fontWeight,  
  fontSize: theme.typography["Typography"]["H6"].fontSize,  
  letterSpacing: theme.typography["Typography"]["H6"].letterSpacing,  
  textDecoration: theme.typography["Typography"]["H6"].textDecoration,  
  lineHeight: theme.typography["Typography"]["H6"].lineHeight,  
  textTransform: theme.typography["Typography"]["H6"].textTransform,  
}));
  
const TopRow1 = styled("div")({  
  display: "flex",  
  flexDirection: "row",  
  justifyContent: "space-between",  
  alignItems: "flex-start",  
  padding: "0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
});
 
function PaymentCard(props) {
  const {data, fns} = usePaymentCard();
  return (
    <TypeQuest>
       <Image bgImage1={props.product.image}>
       </Image>
       {!data.formSubmitted ?
           <Form>
           <Frame1>
             <TextTop>
               <TopRow>
                 <Text>
                   <ConfirmPurchase>
                     Confirm Purchase
                   </ConfirmPurchase>
                   <ItemName>
                     {props.product.name}
                   </ItemName>
                   <ItemPrice>
                     {props.product.price}
                   </ItemPrice>
                 </Text>
                 <X onClick={props.onClose} src={XImage}/>
               </TopRow>
             </TextTop>
             <Frame3>
               <Row1>
                 <TextFieldStandard variant="standard" size="small"  onChange={fns.onNameChange} label="Name on Card" helperText={data.nameError ? data.nameError: "Name of cardholder"} value={data.name} error={Boolean(data.nameError)} />
                 <TextFieldStandard1 variant="standard" size="small"  onChange={fns.onCardNumberChange} label="Card Number" helperText={data.cardError ? data.cardError : " Card Number without dashes or spaces"} value={data.cardNumber} error={Boolean(data.cardError)} />
               </Row1>
               <Row2>
                 <TextFieldStandard2 variant="standard" size="small"  onChange={fns.onCvvChange} label="CVV" helperText={data.cvvError ? data.cvvError: "CVV"} value={data.cvv} error={Boolean(data.cvvError)} />
                 <TextFieldStandard3 variant="standard" size="small"  onChange={fns.onExpDateChange} label="Valid Through" helperText={data.expDateError? data.expDateError : "Exp Date MM/YY or MM/YYYY"} value={data.expDate} error={Boolean(data.expDateError)} />
               </Row2>
             </Frame3>
           </Frame1>
           <Q123>
             <AutoAddedFrame>
               <Button variant="contained" size="large" color="primary"  onClick={fns.handleSubmit} endIcon={ <SvgIcon sx={{width: "18px", height: "22px"}} component = { ArrowForwardFilled1 } />}> BUY </Button>
             </AutoAddedFrame>
           </Q123>
         </Form>
       :null}
       {data.formSubmitted ?
           <Success>
           <Text1>
             <Frame2>
               <Icon1  fontSize = { "large"}  component = { CheckCircleFilled1}/>
             </Frame2>
             <Frame11>
               <PaymentConfirmed>
                 Payment Confirmed!
               </PaymentConfirmed>
               <AConfirmationEmailHa>
                 A confirmation email 
has been sent.
               </AConfirmationEmailHa>
             </Frame11>
           </Text1>
           <Text2>
             <OrderReference>
               Order Reference #
             </OrderReference>
             <Q0021488192>
               0021488192
             </Q0021488192>
             <TopRow1>
             </TopRow1>
           </Text2>
         </Success>
       :null}
     </TypeQuest>
   );
}

export default PaymentCard;
