import './App.css';

import MoonBaseApp from "./moonbase/MoonbaseApp";

function App() {
  return (
    <MoonBaseApp />
  );
}

export default App;
