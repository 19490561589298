/**********************************************************************
*
*   Component hook generated by Quest
*
*   Code Logic for the component goes in this hook
*   To setup bindings that use the data here or call the functions here, use the Quest editor   
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/
import { useState } from "react";

import Hat from './assets/images/Hat.jpg';
import Clock from './assets/images/Clock.jpg';
import Mug from './assets/images/Mug.jpg';
import Outfit from './assets/images/Outfit.jpg';



const useDashboard = () => {
  const products = [
    {
      name: "Moonbase Hat",
      price: "$9.00",
      image: Hat
    },
    {
      name: "Moonbase Clock",
      price: "$12.00",
      image: Clock
    },
    {
      name: "Moonbase Mug",
      price: "$5.00",
      image: Mug
    },
    {
      name: "Moonbase Outfit",
      price: "$29.00",
      image: Outfit
    },
  ];

  let [selectedProduct, setSelectedProduct] = useState(null);
  let [showPayment, setShowPayment] = useState(false);

  let data = { products, selectedProduct, showPayment };

  const closePaymentDialog = () => {
    setShowPayment(false);
  }

  const buyClicked = (product) => {
    setSelectedProduct(product);
    setShowPayment(true);
  }

  let fns = { buyClicked, closePaymentDialog };
  return { data, fns };
}

export default useDashboard;