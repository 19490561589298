/**********************************************************************
*
*   Component generated by Quest
*
*   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts. 
*   To preseve that abilty, always export from Quest to regenerate this file.
*   To setup props, bindings and actions, use the Quest editor
*   Code Logic goes in the hook associated with this component
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

 
const TypeQuest = styled("div")({  
  backgroundColor: "rgba(255, 255, 255, 1)",  
  boxShadow: "0px 4px 6px rgba(3, 0, 161, 0.2)",  
  borderRadius: "8px",  
  display: "flex",  
  flexDirection: "column",  
  width: "390px",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "14px",  
  padding: "0px",  
  boxSizing: "border-box",  
  overflow: "hidden",  
});
  
const Image = styled("div", {
    shouldForwardProp: prop => !["bgImage1"].includes(prop)
  })(({ bgImage1 }) =>({  
  backgroundImage: `url(${bgImage1})`,  
  backgroundPosition: "center",  
  backgroundSize: "cover",  
  backgroundRepeat: "no-repeat",  
  alignSelf: "stretch",  
  height: "171px",  
  overflow: "hidden",  
}));
  
const TextButton = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "14px",  
  padding: "0px 40px 30px 28px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
});
  
const Details = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "2px",  
  padding: "0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
});
  
const ItemName = styled("div")(({ theme }) =>({  
  textAlign: "left",  
  color: theme.palette["text"]["primary"],  
  fontStyle: "normal",  
  fontFamily: "Heebo",  
  fontWeight: "400",  
  fontSize: "18px",  
  letterSpacing: "0px",  
  textDecoration: "none",  
  textTransform: "none",  
  alignSelf: "stretch",  
  height: "24px",  
}));
  
const ItemPrice = styled("div")(({ theme }) =>({  
  textAlign: "left",  
  color: theme.palette["text"]["primary"],  
  fontStyle: "normal",  
  fontFamily: "Heebo",  
  fontWeight: "700",  
  fontSize: "16px",  
  letterSpacing: "0px",  
  textDecoration: "none",  
  textTransform: "none",  
  alignSelf: "stretch",  
}));
 
function ProductCard(props) {
  return (
    <TypeQuest>
       <Image bgImage1={props.product.image}>
       </Image>
       <TextButton>
         <Details>
           <ItemName>
             {props.product.name}
           </ItemName>
           <ItemPrice>
             {props.product.price}
           </ItemPrice>
         </Details>
         <Button variant="contained" size="large" color="primary"  onClick={props.buyClicked}> Buy Now </Button>
       </TextButton>
     </TypeQuest>
   );
}

export default ProductCard;
