/**********************************************************************
*
*   Component generated by Quest
*
*   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts. 
*   To preseve that abilty, always export from Quest to regenerate this file.
*   To setup props, bindings and actions, use the Quest editor
*   Code Logic goes in the hook associated with this component
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/

import { Link } from '@mui/material';
import VectorImage from './assets/images/Vector.png';
import { styled } from '@mui/material/styles';

 
const Upcoming1 = styled("div", {
    shouldForwardProp: prop => !["width1"].includes(prop)
  })(({ width1 }) =>({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "22px",  
  padding: "0px",  
  boxSizing: "border-box",  
  width: width1,  
}));
  
const Upcoming2 = styled("div")({  
  textAlign: "left",  
  color: "rgba(0, 0, 0, 1)",  
  fontStyle: "normal",  
  fontFamily: "Inter",  
  fontWeight: "700",  
  fontSize: "22px",  
  letterSpacing: "0px",  
  textDecoration: "none",  
  textTransform: "none",  
});
  
const Event1 = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "15px",  
  padding: "0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
});
  
const Frame42 = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  padding: "0px",  
  boxSizing: "border-box",  
});
  
const Today = styled("div")({  
  textAlign: "left",  
  color: "rgba(159, 159, 159, 1)",  
  fontStyle: "normal",  
  fontFamily: "Inter",  
  fontWeight: "700",  
  fontSize: "17px",  
  letterSpacing: "0px",  
  textDecoration: "none",  
  lineHeight: "28px",  
  textTransform: "none",  
});
  
const YourFirstDayAtMoonBa = styled("div")({  
  textAlign: "left",  
  color: "rgba(0, 0, 0, 1)",  
  fontStyle: "normal",  
  fontFamily: "Inter",  
  fontWeight: "500",  
  fontSize: "17px",  
  letterSpacing: "0px",  
  textDecoration: "none",  
  lineHeight: "28px",  
  textTransform: "none",  
});
  
const Rectangle10 = styled("div")({  
  backgroundColor: "rgba(218, 218, 218, 1)",  
  alignSelf: "stretch",  
  height: "2px",  
});
  
const Event2 = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "15px",  
  padding: "0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
});
  
const Frame41 = styled("div")({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  padding: "0px",  
  boxSizing: "border-box",  
});
  
const Friday = styled("div")({  
  textAlign: "left",  
  color: "rgba(159, 159, 159, 1)",  
  fontStyle: "normal",  
  fontFamily: "Inter",  
  fontWeight: "700",  
  fontSize: "17px",  
  letterSpacing: "0px",  
  textDecoration: "none",  
  lineHeight: "28px",  
  textTransform: "none",  
});
  
const Setup1StWorkspacePro = styled("div")({  
  textAlign: "left",  
  color: "rgba(0, 0, 0, 1)",  
  fontStyle: "normal",  
  fontFamily: "Inter",  
  fontWeight: "500",  
  fontSize: "17px",  
  letterSpacing: "0px",  
  textDecoration: "none",  
  lineHeight: "28px",  
  textTransform: "none",  
});
  
const Rectangle11 = styled("div")({  
  backgroundColor: "rgba(218, 218, 218, 1)",  
  alignSelf: "stretch",  
  height: "2px",  
});
  
const Link1 = styled("div")({  
  display: "flex",  
  flexDirection: "row",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "11px",  
  padding: "0px",  
  boxSizing: "border-box",  
});
  
const Vector = styled("img")({  
  height: "18px",  
  width: "16px",  
});
 
function Upcoming() {
  return (
    <Upcoming1 width1={"100%"}>
       <Upcoming2>
         Upcoming
       </Upcoming2>
       <Event1>
         <Frame42>
           <Today>
             Today
           </Today>
           <YourFirstDayAtMoonBa>
             Your First Day at Moon Base
           </YourFirstDayAtMoonBa>
         </Frame42>
         <Rectangle10>
         </Rectangle10>
       </Event1>
       <Event2>
         <Frame41>
           <Friday>
             Friday
           </Friday>
           <Setup1StWorkspacePro>
             “Setup 1st Workspace” Project Due
           </Setup1StWorkspacePro>
         </Frame41>
         <Rectangle11>
         </Rectangle11>
       </Event2>
       <Link1>
         <Vector src={VectorImage}/>
         <Link href="#" underline="hover" >Add reminder to your calendar</Link>
       </Link1>
     </Upcoming1>
   );
}

export default Upcoming;
