/**********************************************************************
*
*   Component generated by Quest
*
*   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts. 
*   To preseve that abilty, always export from Quest to regenerate this file.
*   To setup props, bindings and actions, use the Quest editor
*   Code Logic goes in the hook associated with this component
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/

import { Avatar } from '@mui/material';
import MoonbaseLogoImage from './assets/images/MoonbaseLogo.png';
import MoonbaseLogoMImage from './assets/images/MoonbaseLogo_M.png';
import Avatar1Image from './assets/images/Avatar.png';
import { styled } from '@mui/material/styles';
import SidebarRow from './SidebarRow';
import useSidebar from './useSidebar';
 
const CollapsableOpen = styled("div", {
    shouldForwardProp: prop => !["width1", "height1", "overflow1"].includes(prop)
  })(({ width1,height1,overflow1 }) =>({  
  backgroundColor: "rgba(0, 0, 0, 1)",  
  display: "flex",  
  flexDirection: "column",  
  height: height1,  
  width: width1,  
  justifyContent: "space-between",  
  alignItems: "flex-start",  
  padding: "0px",  
  boxSizing: "border-box",  
  overflow: overflow1,  
}));
  
const Top = styled("div", {
    shouldForwardProp: prop => !["width1"].includes(prop)
  })(({ width1 }) =>({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "56px",  
  padding: "20px 0px 0px 0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
  width: width1,  
}));
  
const Logo = styled("div", {
    shouldForwardProp: prop => !["width1"].includes(prop)
  })(({ width1 }) =>({  
  display: "flex",  
  flexDirection: "row",  
  justifyContent: "flex-start",  
  alignItems: "center",  
  padding: "16px 0px 0px 40px",  
  boxSizing: "border-box",  
  width: width1,  
}));
  
const MoonbaseLogo = styled("img")({  
  height: "11.26px",  
  width: "100px",  
});
  
const MoonbaseLogoM = styled("img")({  
  height: "11px",  
  width: "9px",  
});
  
const List = styled("div", {
    shouldForwardProp: prop => !["width1"].includes(prop)
  })(({ width1 }) =>({  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "6px",  
  padding: "0px 0px 0px 22px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
  width: width1,  
}));
  
const SidebarRow1 = styled(SidebarRow)(({ theme }) =>({  
  alignSelf: "stretch",  
  height: "40px",  
}));
  
const Account1 = styled("div", {
    shouldForwardProp: prop => !["width1"].includes(prop)
  })(({ width1 }) =>({  
  backgroundColor: "rgba(43, 43, 43, 1)",  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "center",  
  alignItems: "flex-start",  
  gap: "8px",  
  padding: "0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
  height: "60px",  
  width: width1,  
}));
  
const AvatarRow = styled("div", {
    shouldForwardProp: prop => !["width1"].includes(prop)
  })(({ width1 }) =>({  
  display: "flex",  
  flexDirection: "row",  
  justifyContent: "flex-start",  
  alignItems: "center",  
  gap: "8px",  
  padding: "0px 30px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
  width: width1,  
}));
  
const Avatar1 = styled(Avatar)({  
  width: "32px",  
  height: "32px",  
});
  
const FullName = styled("div", {
    shouldForwardProp: prop => !["width1"].includes(prop)
  })(({ width1 }) =>({  
  textAlign: "left",  
  color: "rgba(255, 255, 255, 1)",  
  fontStyle: "normal",  
  fontFamily: "Inter",  
  fontWeight: "700",  
  fontSize: "16px",  
  letterSpacing: "0.64px",  
  textDecoration: "none",  
  textTransform: "none",  
  flexGrow: "1",  
  width: width1,  
}));
 
function Sidebar() {
  const {data, fns} = useSidebar();
  return (
    <CollapsableOpen onMouseEnter={fns.handleMouseOver} onMouseLeave={fns.handleMouseOut} width1={!data.isHover ? "90px" :  "290px"} height1={"100vh"} overflow1={"hidden"}>
       <Top width1={!data.isHover ? "90px" :  "290px"}>
         <Logo width1={!data.isHover ? "51px" :  "140px"}>
           {!(!data.isHover) ?
               <MoonbaseLogo src={MoonbaseLogoImage}/>
           :null}
           {(!data.isHover) ?
               <MoonbaseLogoM src={MoonbaseLogoMImage}/>
           :null}
         </Logo>
         <List width1={!data.isHover ? "90px" :  "290px"}>
           {data.sidebarItems.map((sidebarItem, index) => {
             return (
               <SidebarRow1  key={index} sidebarItem={sidebarItem} isSelected={index === data.selectedIndex} handleClick={() => fns.handleClick(index)} sidebarHover={data.isHover}/>
             )
           })}
         </List>
       </Top>
       <Account1 width1={!data.isHover ? "90px" :  "290px"}>
         <AvatarRow width1={!data.isHover ? "90px" :  "290px"}>
           <Avatar1 variant="circular" src={Avatar1Image} alt={"Avatar"} />
           {!(!data.isHover) ?
               <FullName width1={!data.isHover ? "1px" :  "190px"}>
               Ellen Cho
             </FullName>
           :null}
         </AvatarRow>
       </Account1>
     </CollapsableOpen>
   );
}

export default Sidebar;
