/**********************************************************************
*
*   Component generated by Quest
*
*   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts. 
*   To preseve that abilty, always export from Quest to regenerate this file.
*   To setup props, bindings and actions, use the Quest editor
*   Code Logic goes in the hook associated with this component
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/

import { styled } from '@mui/material/styles';
import Reaction from './Reaction';
import useFeedbackBar from './useFeedbackBar';
 
const FeedbackBar1 = styled("div", {
    shouldForwardProp: prop => !["width1"].includes(prop)
  })(({ width1 }) =>({  
  background: "linear-gradient(89.34deg, rgba(83, 178, 246, 1) 9.171567099603285%, rgba(124, 98, 255, 1) 90.77521726346906%)",  
  border: "1px solid rgba(176, 176, 176, 0.5)",  
  boxSizing: "border-box",  
  borderRadius: "8px",  
  display: "flex",  
  flexDirection: "column",  
  width: width1,  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  gap: "20px",  
  padding: "28px 38px 38px 38px",  
}));
  
const FeedbackText = styled("div")(({ theme }) =>({  
  textAlign: "left",  
  color: theme.palette["Light"]["Primary"]["Contrast"],  
  fontStyle: "normal",  
  fontFamily: "Inter",  
  fontWeight: "400",  
  fontSize: "24px",  
  letterSpacing: "0px",  
  textDecoration: "none",  
  lineHeight: "28px",  
  textTransform: "none",  
}));
  
const Btm = styled("div")({  
  display: "flex",  
  flexDirection: "row",  
  justifyContent: "center",  
  alignItems: "center",  
  padding: "0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
});
  
const Frame3 = styled("div")({  
  display: "flex",  
  flexDirection: "row",  
  justifyContent: "space-between",  
  alignItems: "center",  
  padding: "0px",  
  boxSizing: "border-box",  
  flexGrow: "1",  
});
  
const Reaction1 = styled(Reaction)(({ theme }) =>({  
  width: "221px",  
  height: "42px",  
}));
 
function FeedbackBar() {
  const {data, fns} = useFeedbackBar();
  return (
    <FeedbackBar1 width1={"100%"}>
       <FeedbackText>
         {fns.getFeedbackText()}
       </FeedbackText>
       <Btm>
         <Frame3>
           {data.reactions.map((reaction, index) => {
             return (
               <Reaction1  key={index} isSelected={index === data.selectedIndex} reactionInfo={reaction} handleClick={() => fns.handleClick(index)}/>
             )
           })}
         </Frame3>
       </Btm>
     </FeedbackBar1>
   );
}

export default FeedbackBar;
