/**********************************************************************
*
*   Component generated by Quest
*
*   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts. 
*   To preseve that abilty, always export from Quest to regenerate this file.
*   To setup props, bindings and actions, use the Quest editor
*   Code Logic goes in the hook associated with this component
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/

import { Avatar } from '@mui/material';
import Avatar1Image from './assets/images/Avatar.png';
import { styled } from '@mui/material/styles';

 
const NavBar1 = styled("div", {
    shouldForwardProp: prop => !["width1"].includes(prop)
  })(({ width1 }) =>({  
  display: "flex",  
  flexDirection: "column",  
  height: "80px",  
  width: width1,  
  justifyContent: "flex-start",  
  alignItems: "flex-start",  
  padding: "0px",  
  boxSizing: "border-box",  
}));
  
const Content = styled("div")({  
  backgroundColor: "rgba(255, 255, 255, 1)",  
  display: "flex",  
  flexDirection: "column",  
  justifyContent: "center",  
  alignItems: "flex-end",  
  padding: "0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
  height: "80px",  
});
  
const RightSide2 = styled("div")({  
  display: "flex",  
  flexDirection: "row",  
  justifyContent: "flex-end",  
  alignItems: "flex-end",  
  gap: "2px",  
  padding: "0px 28px 0px 0px",  
  boxSizing: "border-box",  
  alignSelf: "stretch",  
  flexGrow: "1",  
});
  
const Account = styled("div")({  
  display: "flex",  
  flexDirection: "row",  
  justifyContent: "flex-end",  
  alignItems: "center",  
  gap: "18px",  
  padding: "0px",  
  boxSizing: "border-box",  
});
  
const Avatar1 = styled(Avatar)({  
  width: "40px",  
  height: "40px",  
});
  
const EllenCho = styled("div")({  
  textAlign: "left",  
  color: "rgba(0, 0, 0, 1)",  
  fontStyle: "normal",  
  fontFamily: "Inter",  
  fontWeight: "700",  
  fontSize: "16px",  
  letterSpacing: "0.64px",  
  textDecoration: "none",  
  textTransform: "none",  
  width: "127px",  
  height: "25.83px",  
});
 
function NavBar() {
  return (
    <NavBar1 width1={"100%"}>
       <Content>
         <RightSide2>
           <Account>
             <Avatar1 variant="circular" src={Avatar1Image} alt={"Avatar"} />
             <EllenCho>
               Ellen Cho
             </EllenCho>
           </Account>
         </RightSide2>
       </Content>
     </NavBar1>
   );
}

export default NavBar;
